.App {
  font-family: Arial, sans-serif;
  background-color: #02160d;
  color: #ffffff;
  margin: 0;
  padding: 0;
}

header {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
}

.connect-wallet {
  padding: 10px 20px;
  background-color: #00ff9c;
  color: #000000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  text-align: center;
}

.logo {
  display: block;
  margin: 0 auto 20px;
}

h1 {
  color: #00ff9c;
  margin-bottom: 30px;
}

.input-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

button {
  padding: 12px 20px;
  background-color: #00ff9c;
  color: #000000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button span {
  font-weight: bold;
}

.info {
  margin-bottom: 30px;
}

.address {
  color: #00ff9c;
  padding: 30px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 40px;
}

th, td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ffffff;
}

th {
  background-color: #00ff9c;
  color: #000000;
}

tbody tr:nth-child(odd) {
  background-color: #051f14;
}

.address-info {
  color: #00ff9c;
  padding: 10px;
}

.error {
  color: #ff4d4d;
  background-color: #330000;
  border: 1px solid #ff0000;
  padding: 10px;
  margin: 20px 0;
  border-radius: 5px;
  font-weight: bold;
}

.faucet-links {
  display: block;
  margin-top: 20px;
  color: #00ff9c;
  text-decoration: none;
  font-weight: bold;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

/* .faucet-links:hover {
  background-color: #00ff9c;
  color: #000000;
} */

.faucet-links-page {
  text-align: center;
  padding: 20px;
}

.faucet-links-page ul {
  list-style-type: none;
  padding: 0;
}

.faucet-links-page li {
  margin: 10px 0;
}

.faucet-links-page a {
  color: #00ff9c;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
}

.faucet-links-page a:hover {
  background-color: #00ff9c;
  color: #000000;
  padding: 5px;
  border-radius: 5px;
}


.faucet-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
